import Caver from 'caver-js';
import { fetchData } from "../data/dataActions";

let networkAddress = "https://public-node-api.klaytnapi.com/v1/cypress"; // baobab : https://api.baobab.klaytn.net:8651/  , mainnet : https://public-node-api.klaytnapi.com/v1/cypress

const connectRequest = () => {
    return {
      type: "CONNECTION_REQUEST",
    };
  };
  
  const connectSuccess = (payload) => {
    return {
      type: "CONNECTION_SUCCESS",
      payload: payload,
    };
  };
  
  const connectFailed = (payload) => {
    return {
      type: "CONNECTION_FAILED",
      payload: payload,
    };
  };
  
  const updateAccountRequest = (payload) => {
    return {
      type: "UPDATE_ACCOUNT",
      payload: payload,
    };
  };

  export const connect = () => {
      return async (dispatch) => {
          dispatch(connectRequest());
          const abiResponse = await fetch("/config/abi.json", {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
          });
          const abi = await abiResponse.json();
          const configResponse = await fetch("/config/config.json", {
              headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                },
          });
          const CONFIG = await configResponse.json();
          let caver = new Caver(window.klaytn);

          try {
            let contract = new caver.contract.create(abi, CONFIG.CONTRACT_ADDRESS);
            const [address] = await window.klaytn.enable();
            dispatch(
                connectSuccess({
                    account: address,
                    smartContract: contract,
                    web3: caver,
                })
            );
          } catch (err) {
              console.log(err);
              dispatch(connectFailed("Something went wrong."));
          }
      }
  }